body {
  margin: 1em auto;
  max-width: 1000px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.navbar-nav div {
  cursor: pointer;
}

.navbar-nav div.active {
  text-decoration: underline;
}

.gameEditForm {
  margin-left: 2em;
}

.counter {
  color: #999;
  font-size: 90%;
}

span.expansion {
  font-size: small;
  font-variant-caps: all-small-caps;
  color: darkgreen;
  margin-right: 0.2rem;
}